import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Grid from "../../../../../components/grid"
import Stack from "../../../../../components/stack"
import Poem from "../../../../../components/poem"
import CourseFooterNext from "../../../../../components/course-footer-next"
import OrdnungTask from "../../../../../content/kurse/versteckte-ordnung/02-ordnung/task"
import PaperStack from "../../../../../components/paper-stack"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "versteckte-ordnung",
          chapterId: "02-ordnung",
          taskId: "inventur",
        })
        navigate("/kurse/versteckte-ordnung/02-ordnung/inventur/loesung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Günther Eich: Inventur" />
      <Stack>
        <Stack space={3}>
          <Heading as="span">Günter Eich</Heading>
          <Heading as="h1" level={2}>
            Inventur
          </Heading>
        </Stack>
        <Grid columns={[1, 2]}>
          <PaperStack>
            <Poem size={[3, 3, 4]}>
              <p>Dies ist meine Mütze,</p>
              <p>dies ist mein Mantel,</p>
              <p>hier mein Rasierzeug</p>
              <p>im Beutel aus Leinen.</p>

              <br />
              <p>Konservenbüchse:</p>
              <p>Mein Teller, mein Becher,</p>
              <p>ich hab in das Weißblech</p>
              <p>den Namen geritzt.</p>

              <br />
              <p>Geritzt hier mit diesem</p>
              <p>kostbaren Nagel,</p>
              <p>den vor begehrlichen</p>
              <p>Augen ich berge.</p>

              <br />
              <p>Im Brotbeutel sind</p>
              <p>ein Paar wollene Socken</p>
              <p>und einiges, was ich</p>
              <p>niemand verrate,</p>

              <br />
              <p>so dient es als Kissen</p>
              <p>nachts meinem Kopf.</p>
              <p>Die Pappe hier liegt</p>
              <p>zwischen mir und der Erde.</p>

              <br />
              <p>Die Bleistiftmine</p>
              <p>lieb ich am meisten:</p>
              <p>Tags schreibt sie mir Verse,</p>
              <p>die nachts ich erdacht.</p>

              <br />
              <p>Dies ist mein Notizbuch,</p>
              <p>dies meine Zeltbahn,</p>
              <p>dies ist mein Handtuch,</p>
              <p>dies ist mein Zwirn.</p>
            </Poem>
          </PaperStack>
          <OrdnungTask />
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
